import React, {Component} from 'react';
import { Link } from 'react-router-dom';

import axios from 'axios';
import { API, CLIENT, ASSETS_URL } from '../_helpers/Constants';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';


export default class Vertical_1 extends Component {
  constructor(props){
    super(props);
    this.state = {
        total : props.total,
        type : props.type,
        category : props.category,
        showBig1 : props.showBig1,
        dateFrom : props.dateFrom || '2019-01-01',
        dateTo : props.dateTo || '2025-01-01',
        data :[],
        keyword : props.keyword || '',
        isLoading : false,
        client : JSON.parse(localStorage.getItem("_profile")),
        isHeadline : props.isHeadline || 0
    }
  }

  ImageHelper(ev){
    var client = JSON.parse(localStorage.getItem('_profile'));
    ev.target.src = ASSETS_URL + "img/logo/" + client.subdomain_logo
  }
  
  componentDidMount(){
    this.getDataSlider();
  }
  getDataSlider(){
    this.setState({ isLoading : true });

    var self = this;
    var urls =  API + "news/getall?username="+ localStorage.getItem('_username')
        + "&token="+ localStorage.getItem('_token') 
        + "&subdomain_id=" + CLIENT
        + "&limit="+ this.state.total
        + "&type="+ (this.state.type === 'printed' ? 3 : (this.state.type === 'tv' ? 2 : 1)  )
        + "&headline="+this.state.isHeadline
        + (this.state.keyword !== '' ? ('&title='+ this.state.keyword) : '')
        + "&date_from="+ this.state.dateFrom +"&date_to=" + this.state.dateTo;
    // console.log(urls);
    axios({
        method: 'get',
          url: urls,
      }).then(function (response) {
          if(response.data.status === 200){
              // console.log(response.data.data);
            self.setState({ data : response.data.data, isLoading : false});
          }else{
            self.setState({  isLoading : false});
            // alert(response.data.message);
          }
      }).catch(function (error) {
            self.setState({  isLoading : false});
      });
  }

  forceUpdateComponent(dateFrom, dateTo, keyword = ''){
    this.setState({
        dateFrom : dateFrom,
        dateTo : dateTo,
        keyword : keyword || this.state.keyword
      }, () => {
        this.getDataSlider();
      })
  }

 
  render(){


    return (
        <div>
            {this.state.isLoading ? <FontAwesomeIcon style={{marginRight:5}} icon={faSpinner} spin/> : '' }
                { this.state.data.map((item, i) => {
                    if (this.state.showBig1 === "true" && i === 0) { 
                        return  <div key={i}  className="single-blog-post featured-post-2">
                                    <div className="post-thumb">
                                        <Link to={`/news/${item.berita_id}`}  ><img
                                         style={{
                                            alignSelf: 'cover',
                                            height: 160,
                                            width: '100%',
                                          }}
                                          onError={this.ImageHelper}
                                           src={ASSETS_URL + "/img/photo/" + item.berita_foto} alt="" /></Link>
                                    </div>
                                    <div className="post-data">
                                        <Link to={`/news/${item.berita_id}`}  className="post-catagory" style={{color:this.state.client.subdomain_color}}>{this.state.type}</Link>
                                        <div className="post-meta">
                                            <Link to={`/news/${item.berita_id}`}  className="post-title">
                                                <h6>{item.berita_judul} </h6>
                                            </Link>
                                            <p className="post-date"><span>{item.berita_jam_tampil}</span> | <span>{item.berita_tanggal_tampil}</span></p>

                                        </div>
                                    </div>
                                </div>
                    }else{
                        return <div key={i} className="single-blog-post small-featured-post d-flex">
                                    <div className="post-thumb">
                                        <Link to={`/news/${item.berita_id}`}  ><img 
                                         style={{
                                            alignSelf: 'center',
                                            height: 90,
                                            width: '100%',
                                          }}
                                          onError={this.ImageHelper}
                                          src={require('../_assets/img/logo.png')} alt="" /></Link>
                                    </div>
                                    <div className="post-data">
                                        <Link to={`/news/${item.berita_id}`}   className="post-catagory">{item.topik_nama}</Link>
                                        <div className="post-meta">
                                            <Link to={`/news/${item.berita_id}`}   className="post-title">
                                                <h6>{item.berita_judul}</h6>
                                            </Link>
                                            <p className="post-date"><span>{item.berita_jam_tampil}</span> | <span>{item.berita_tanggal_tampil}</span></p>
                                        </div>
                                    </div>
                                </div>
                    }
                        
                        
                    })
                }




        </div>

        )
  }
 
}

